import React, { useState } from "react"
import {Container, Row, Col, Progress} from "reactstrap"
import styled from "styled-components"

import ServicesSubSectionHeading from "./ServicesSubSectionHeading"
import leftArrowDisabled from "../../images/common/carousel_arrows/left-arrow-disabled.png"
import leftArrowEnabled from "../../images/common/carousel_arrows/left-arrow-enabled.png"
import rightArrowDisabled from "../../images/common/carousel_arrows/right-arrow-disabled.png"
import rightArrowEnabled from "../../images/common/carousel_arrows/right-arrow-enabled.png"

const TechnologiesCarouselDiv = styled.div`
    text-align: center;
`

const TechnologiesCarouselButtonStyle = styled.input`
    background-color: transparent;
    border: none;
    font-weight: bolder;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 15px;

    :focus {
        outline: 0;
    }
`

const CarouselBodyText = styled.p`
    font-size: 14px;
    color: #454f5b;
    font-family: 'Libre Franklin';
    margin-top: 20px;
`

const ListLabelButton = styled.button`
    border-radius: 20px;
    border: none;
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    color: white;
    font-family: 'Libre Franklin';
    font-size: 14px;
`

const ServicesProgress = styled(Progress)`
    height: 5px;
`

const ServicesTypesCarousel = (props) => {

    const allServices = props.allServices;
    const [index, setIndex] = useState(0);
    const tabsCount = allServices.length;

    const clickNext = () => {
        if (index !== tabsCount - 1) setIndex(index + 1);
    }

    const clickPrev = () => {
        if (index !== 0) setIndex(index -1);
    }

    function returnProgressBarValue(){
        return (index + 1) / tabsCount * 100;
    }

    function leftButtonDisabled(){
        return index === 0;
    }

    function rightButtonDisabled(){
        return index === tabsCount - 1;
    }

    function returnLabels(){
        let listLabels = []
        let listItems = allServices[index].technologyBulletList;
        let colours = [
            '#f56d18',
            '#108043',
            '#007ace'
        ]
        for (let index in listItems){
            listLabels.push(
                <Col xs="6" key={index}>
                    <ListLabelButton
                        disabled
                        style={{
                            backgroundColor: colours[index % 3]
                        }}>
                        {listItems[index]}
                    </ListLabelButton>
                </Col>
            )
        }
        return listLabels
    }

    return (
        <Container>
            <Row>
                <Col>
                    <ServicesSubSectionHeading>
                        {allServices[index].title}
                    </ServicesSubSectionHeading>
                </Col>
                <Col>
                    <TechnologiesCarouselDiv>
                        <TechnologiesCarouselButtonStyle
                            type="image"
                            disabled={leftButtonDisabled()}
                            onClick={clickPrev}
                            src={leftButtonDisabled() ? leftArrowDisabled : leftArrowEnabled}
                            color="black">
                        </TechnologiesCarouselButtonStyle>
                        <TechnologiesCarouselButtonStyle
                            type="image"
                            disabled={rightButtonDisabled()}
                            onClick={clickNext}
                            src={rightButtonDisabled() ? rightArrowDisabled : rightArrowEnabled}
                            color="black">
                        </TechnologiesCarouselButtonStyle>
                    </TechnologiesCarouselDiv>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ServicesProgress value={returnProgressBarValue()}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CarouselBodyText>
                        {allServices[index].bodyText.bodyText}}
                    </CarouselBodyText>
                </Col>
            </Row>
            <Row>
                {returnLabels()}
            </Row>
        </Container>
    )
}


export default ServicesTypesCarousel;
