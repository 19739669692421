import React from "react"
import {Row, Col } from "reactstrap"
import styled from 'styled-components'
import breakpoint from "styled-components-breakpoint"

import BulletList from "./BulletList"
import ServicesSubSectionHeading from "./ServicesSubSectionHeading"

const ServicesSubSectionRow = styled(Row)`
    margin-top: 40px;
    padding-top: 100px;
`

const BulletListSubSection = styled.div`
    display: flex;
    align-items: center;
    padding-top: 40px;

    ${breakpoint('md')`
        padding-left: 100px;
    `}
`

const ServicesColumnText = styled.p`
	font-size: 14px;
	color: #454f5b;
    font-family: 'Libre Franklin';

    ${breakpoint('sm')`
        font-size: 16px;
    `}
`

const ServicesSubSection = ({ serviceName, serviceText, serviceBullets }) => (
    <ServicesSubSectionRow>
        <Col md="6" sm="12">
            <ServicesSubSectionHeading>
                {serviceName}
            </ServicesSubSectionHeading>
            <ServicesColumnText>
                {serviceText}
            </ServicesColumnText>
        </Col>
        <Col md="6" sm="12">
            <BulletListSubSection>
                <BulletList
                    title={'Technologies'}
                    listItems={serviceBullets} />
            </BulletListSubSection>
        </Col>
    </ServicesSubSectionRow>
);

export default ServicesSubSection;
