import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint";

const BulletListTitle = styled.h3`
    font-size: 14px;
    font-weight: bold;
    color: #454f5b;
    font-family: 'Libre Franklin';
    font-weight: 500;
    margin-bottom: 20px;

    ${breakpoint('sm')`
        font-size: 16px;
    `}
`

const BulletListItem = styled.li`
    font-size: 14px;
    color: #919eab;
    font-family: 'Libre Franklin';
    ${breakpoint('sm')`
        font-size: 16px;
    `}
`

//This is a fix just for firefox where the bullet alignment is weird
const BulletIndentDiv = styled.div`
@-moz-document url-prefix() {
    padding-left: 16px
}
`

function constructBulletList(listItems) {
    if (!listItems) {
        return null;
    }
    let bulletListItems = [];
    for (let i in listItems){
        bulletListItems.push(
            <BulletListItem key={i}>
                {listItems[i]}
            </BulletListItem>
        )
    }
    return bulletListItems;
}

const BulletList = ({ title, listItems }) => (
    <div>
        <BulletListTitle>{title}</BulletListTitle>
        <BulletIndentDiv>
            {constructBulletList(listItems)}
        </BulletIndentDiv>
    </div>
);

export default BulletList;
