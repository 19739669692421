import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const ServicesSubSectionHeading = styled.h5`
    font-size: 16px;
    color: #f56d18;
    font-family: 'Libre Franklin';
    font-weight: 500;

    ${breakpoint('sm')`
        font-size: 20px;
    `}
`

export default ServicesSubSectionHeading;
