import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Divider = styled.hr`
	display: none;
	border-color: #dfe3e9;

	${breakpoint('sm')`
		display: block;
		margin-top: 120px;
		margin-bottom: 120px;
	`}
`

export default Divider
