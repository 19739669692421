import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ServicesComponent from "../components/Services/Services"
import { graphql } from 'gatsby'


const Services = (props) => (
	<Layout>
		<SEO title="Services"/>
		<ServicesComponent  services={props.data}/>
	</Layout>
)

export const query = graphql`
  {
    allContentfulService {
      nodes {
        bodyText {
          bodyText
        }
        buttonText
        isOffered
        title
        technologyBulletList
				anchor
      }
    }
  }
`

export default Services
